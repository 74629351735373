import React from 'react'
import '../../style/footage.css'

const footage = () => {
  return (
    <>
    <section className='footage'>
        <p>© 2024 Temerachi Coffee Export. All Rights Reserved.</p>
    </section>
      
    </>
  )
}

export default footage
