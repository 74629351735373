export const nav = [
    {
        text: "Our Story",
        path: "/ourStory", // Corrected path to match routes
    },
    {
        text: "Gallery",
        path:"/gallery",
        
    },
    {
        text: "Blog",
        path: "/blog", // Corrected path to match routes
    },
    {
        text: "Contact Us",
        path: "/contact", // Corrected path to match routes
    },
    {
        text:"Sample Order",
        path: "/sampleorder"
    }

];

